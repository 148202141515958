@import 'scss/variables';
@import 'scss/mixins';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
// @import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
// @import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
// @import '~bootstrap/scss/breadcrumb';
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/jumbotron';
// @import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
// @import '~bootstrap/scss/toasts';
// @import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import '~react-toastify/scss/main';

:root {
  --black: #{$black};
}

body {
  font-size: $body-font-size;
  margin: 0;
  padding: 0;
}

a {
  &,
  &:hover {
    color: var(--link-color);
  }
}

.font-weight-medium {
  font-weight: 500 !important;
}

.text-muted {
  color: $text-muted !important;
}

.text-grey-50 {
  color: $grey-50 !important;
}

.subheadline {
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.29;
  margin-bottom: 0;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.card {
  backdrop-filter: blur(55px);
  box-shadow: $card-shadow;

  @include media-breakpoint-only(xs) {
    border-radius: 0;
  }
}

.card-body {
  @include media-breakpoint-only(xs) {
    padding: $card-spacer-y-xs $card-spacer-x-xs;
  }
}

// TODO: Move to buttons, create button variants
.btn {
  &.btn-flat {
    background: var(--button-background-color);
    border-style: solid;
    border-width: 1px;
    color: var(--button-color);
    outline-color: currentColor;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      border-color: var(--button-color);
    }

    &:active {
      border-color: transparent;
    }
  }

  &.btn-flat,
  &.btn-outline {
    &:active {
      box-shadow: none;
    }
  }

  &.btn-outline-light {
    color: #17171E;
    border-color: $gray-600;
  }
}

.btn-icon-left {
  margin-right: 4px;
  vertical-align: text-top;
}

.btn-icon-right {
  margin-left: 4px;
  vertical-align: text-top;
}

.form-label {
  color: $gray-800;
  font-size: 0.8125rem;
}

.container.gutter-xs-0 {
  @include media-breakpoint-only(xs) {
    padding-left: 0;
    padding-right: 0;

    > .row {
      margin-left: 0;
      margin-right: 0;

      > .col-14 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

h1, .h1 {
  line-height: 1.14;
}

h2, .h2 {
  line-height: 1.17;
}

h3, .h3 {
  line-height: 1.15;
}

h4, .h4 {
  line-height: 1.24;
}

h5, .h5 {
  line-height: 1.23;
}

h6, .h6 {
  line-height: 1.18;
}

.footnote {
  font-size: 0.8125rem;
  line-height: 1.54;
}

