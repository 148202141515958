@import 'colors';

// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 8px;
$border-radius-lg: 8px;
$border-radius-sm: 8px;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Inter', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.5;
$h4-font-size: $font-size-base * 2.125;
$h5-font-size: $font-size-base * 1.625;
$h6-font-size: $font-size-base * 1.375;

$headings-line-height: 1.2;
$headings-font-weight: 400;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-font-size: 0.9375rem;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1rem;
$input-border-radius: 8px;
$input-border-color: $gray-600;
$input-color: $body-color;
$input-placeholder-color: $gray-800;
$label-margin-bottom: 4px;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y: 16px;
$btn-padding-x: 24px;
$btn-line-height: 1;
$btn-border-radius: 8px;
$btn-font-weight: 500;

// Navbar
$navbar-padding-y: 18px;
$navbar-padding-y-xs: 14px;
$navbar-brand-font-size: 1.5625rem;

// Cards
$card-spacer-y: 32px;
$card-spacer-x: 32px;
$card-shadow: 0 25px 50px rgba(65, 64, 66, 0.08);

$card-spacer-y-xs: 16px;
$card-spacer-x-xs: 16px;

$card-border-width: 0;
$card-bg: rgba(255, 255, 255, 0.95);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// NOTE: "$container-width - X" to prevent sass "Assert ascending" error
$container-width: 1456px;
$container-max-widths: (
  sm: $container-width - 3px,
  md: $container-width - 2px,
  lg: $container-width - 1px,
  xl: $container-width
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 14;
$grid-gutter-width: 24px;

// Toastify
$rt-color-info: $info;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;
$rt-toast-min-height: 48px;

$body-font-size: 0.9375rem;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
