// Override Bootstrap defaults set in node_modules/bootstrap/scss/_variables.scss
//
// Color system
$white: #ffffff;
$gray-900: #343a40;
$gray-800: #6c757d;
$gray-700: #93969b;
$gray-600: #d6dbdf;
$gray-500: #f8f9fa;
$gray-400: #f3f3f7;
$gray-300: $gray-400;
$gray-200: $gray-400;
$gray-100: $gray-400;
$black: $gray-900;

$blue: #3745ff;
$orange: #ff5900;
$red: #e21111;
$green: #65c514;
$yellow: #ffca37;

$primary: $blue;
$secondary: $orange;
$success: $green;
$warning: $orange;
$danger: $red;
$info: $gray-800;

$body-bg: $white;
$body-color: $gray-900;
$link-color: $primary;
$text-muted: $gray-800;
$grey-50: #A3A6AA;
